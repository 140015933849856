import { firebase } from "@firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import { CircularProgress, CssBaseline } from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as fns from "./fns";

const WHITEBOARD_ID = "whiteboard-id";

const toJS = x => x;

const useCollection = query => {
  const [coll, setColl] = React.useState(null);
  const [q, setQ] = React.useState(query);

  if (!query.isEqual(q)) {
    setQ(query);
  }

  useEffect(() => {
    const unsubscribe = q.onSnapshot(
      snapshot => {
        const coll = toJS(
          snapshot.docs.map(x => {
            let r = { id: x.id, ...x.data() };
            // TODO: Experimental with this _ref
            Object.defineProperty(r, "_ref", {
              enumerable: false,
              writable: true
            });
            r._ref = x.ref;
            return r;
          })
        );
        setColl(coll);
      },
      e => {
        console.warn(e);
      }
    );

    return unsubscribe;
  }, [q]);

  return [coll, setColl];
};

const useStyles = makeStyles(() =>
  createStyles({
    table: {}
  })
);

const StyledTableCell = withStyles(theme => ({
  root: {
    padding: "16px 6px 16px 10px"
  },
  head: {
    backgroundColor: "#2B59C3",
    color: theme.palette.common.white,
    paddingTop: 4,
    paddingBottom: 4
  }
}))(TableCell);

const StyledTableCellImage = withStyles(theme => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "8px"
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const textFormatter = s =>
  s
    .toString()
    .split("\n")
    .map((x, i) => (
      <React.Fragment key={`${i}_text`}>
        {x} <br />
      </React.Fragment>
    ));

const Cell = ({ c, h }) => {
  return c.name === "Name" ? (
    <StyledTableCellImage align="center">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center"
        }}
      >
        <img
          alt="horse"
          style={{
            height: 38,
            width: 38,
            borderRadius: 19,
            marginRight: 6
          }}
          align="center"
          src={h["image"]}
        />
        {h[c.name.toLowerCase()]}
      </div>
    </StyledTableCellImage>
  ) : (
    <StyledTableCell align={c.align || "left"}>
      {c.formatter
        ? c.formatter(h)
        : textFormatter(h[c.name.toLowerCase()] || "-")}

      {false &&
        (h[c.name.toLowerCase()] || "-")
          .toString()
          .split("\n")
          .map((x, i) => (
            <React.Fragment key={`${i}_text`}>
              {x} <br />
            </React.Fragment>
          ))}
    </StyledTableCell>
  );
};

const formatLiters = horse => {
  const day = Math.floor(new Date().valueOf() / (24 * 60 * 60 * 1000));
  if (horse?.consumption?.day === day) {
    return horse?.consumption?.liters?.toFixed(2);
  } else {
    return "-";
  }
};

const Whiteboard = ({ app }) => {
  const columns = [
    { name: "Box", align: "center" },
    { name: "Name" },
    { name: "Info" },
    { name: "Food" },
    {
      name: "Consumption",
      label: "Water",
      formatter: formatLiters
    }
  ];

  const [horses] = useCollection(
    firebase
      .firestore()
      .collection("horses")
      //  .where("owner", "==", "IA7MA4pwvWe926jVnrvq3V757he2")
      .where("owner", "==", "7N8pJd41YiMBBzL5isKDYW2gMmI3")
    //.where("owner", "==", app.authUser.uid)
  );

  const classes = useStyles();
  if (!horses) {
    return <Spinner />;
  }

  return (
    <div style={{ display: "flex", width: "100%", fontSize: "24px" }}>
      <TableContainer>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map(c => (
                <StyledTableCell key={c.name} align={c.align || "left"}>
                  {c.label || c.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {horses.map(h => (
              <StyledTableRow key={`${h.name}_2`}>
                {columns.map((c, i) => (
                  <Cell key={`${c.name}_${h.name}_${i}`} c={c} h={h} />
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const Auth = () => {
  const [id] = useState(localStorage.getItem(WHITEBOARD_ID));
  const [secret, setSecret] = useState(uuidv4());

  useEffect(() => {
    (async () => {
      if (id && secret) {
        await fns.setQrChallenge({ id, secret });
      }
    })();
  }, [id, secret]);

  useEffect(() => {
    const f = async () => {
      setSecret(uuidv4());
    };

    const t = setInterval(f, 60000);

    return () => {
      clearInterval(t);
    };
  }, []);

  useEffect(() => {
    const f = async () => {
      const r = await fns.getQrAuthToken({ id, secret });
      if (r?.data?.status === "ok") {
        /*const userCred =*/ await firebase
          .auth()
          .signInWithCustomToken(r?.data?.token);
      }
    };

    const t = setInterval(f, 2000);

    return () => {
      clearInterval(t);
    };
  }, [id, secret]);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <img
        alt="logo"
        style={{ width: "60%", maxWidth: 600 }}
        src="https://firebasestorage.googleapis.com/v0/b/wasserfall.appspot.com/o/cms%2Fimages%2Fwhiteboard-splash.png?alt=media&token=08b59619-c7ee-4bc5-b286-5e836e70df3e"
      />
      <div style={{ height: 40 }} />

      {/*
      When testing locally with the Expo use something the following and replace IP with your host IP
      `exp://192.168.86.245:19000/--/authqr/${id}/${secret}` */}
      <QRCode
        size={200}
        value={`https://wasserfall.se/authqr/${id}/${secret}`}
      />
      <div style={{ marginTop: 32, fontSize: "2.5em" }}>
        Scan QR-code with your Phone
      </div>
    </div>
  );
};

const Spinner = () => (
  <div
    style={{
      display: "flex",
      flex: 1,
      width: "100%",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center"
    }}
  >
    <CircularProgress size={64} />
  </div>
);

function WhiteboardApp() {
  const [waitAuthChanged, setWaitAuthChanged] = useState(true);
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    let id = localStorage.getItem(WHITEBOARD_ID);
    if (!id) {
      id = uuidv4();
    }
    localStorage.setItem(WHITEBOARD_ID, id);
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      setWaitAuthChanged(false);
      setAuthUser(user);
    });
  }, []);

  const app = { authUser };

  if (waitAuthChanged) {
    return <Spinner />;
  }

  return (
    <div
      style={{ display: "flex", width: "100%" }}
      onDoubleClick={() => firebase.auth().signOut()}
    >
      <CssBaseline>
        {authUser ? <Whiteboard app={app} /> : <Auth />}
      </CssBaseline>
    </div>
  );
}

const Home = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        height: "100vh",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center"
        }}
      >
        <img
          alt="logo"
          style={{ width: "90%", maxWidth: 500 }}
          src="https://firebasestorage.googleapis.com/v0/b/wasserfall.appspot.com/o/cms%2Fimages%2Fwhiteboard-splash.png?alt=media&token=08b59619-c7ee-4bc5-b286-5e836e70df3e"
        />
      </div>
    </div>
  );
};

function App() {
  return (
    <div
      style={{ display: "flex", width: "100%" }}
      onDoubleClick={() => firebase.auth().signOut()}
    >
      <Router>
        <Switch>
          <Route path="/whiteboard">
            <WhiteboardApp />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
