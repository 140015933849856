import firebase from "firebase/app";

const REGION = "europe-west1";

const mkFun = (name) => async (data) => {
    let fn = null;

    if (process.env.REACT_APP_FUNCTION_REGION === "local") {
        fn = firebase.app().functions().httpsCallable(name);
    } else {
        fn = firebase.app().functions(REGION).httpsCallable(name);
    }

    return await fn(data);
};

export const setQrChallenge = mkFun("setQrChallenge");
export const getQrAuthToken = mkFun("getQrAuthToken");
