import { firebase } from "@firebase/app";
import "@firebase/functions";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const firebaseConfig = {
  apiKey: "AIzaSyDnFZIlS_k37tYGVUt3izEqmUcn2OCfsZc",
  authDomain: "wasserfall.firebaseapp.com",
  projectId: "wasserfall",
  storageBucket: "wasserfall.appspot.com",
  messagingSenderId: "626345232188",
  appId: "1:626345232188:web:b74bedee8cad0fa7778039",
  measurementId: "G-0ZW0NDK2T7"
};

firebase.initializeApp(firebaseConfig);

if (process.env.REACT_APP_FUNCTION_REGION === "local") {
  const host = process.env.REACT_APP_FUNCTION_HOST || "localhost";
  console.log(`Using local function emulator at http://${host}:5001`);
  firebase.functions().useEmulator(host, 5001);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
